import { LocalizeVariable } from "utils/env";

const ENTRY_LIMIT = {
  NAME: 50,
  TEXT_FIELD: {
    SHORT: 30,
    MIDDLE: 100,
    LONG: 200
  },
  TEXT_AREA: {
    SHORT: 100,
    MIDDLE: 200,
    LONG: 500
  },
  PRICE_FIELD: 15,
  GOLD_FIELD: 8,
  PERCENT_FIELD: 3,
  SHORTCUT_FIELD: 5,
  PHONE_NO_FIELD: 30,
  ADDRESS_INPUT_FIELD: 300,
  EMAIL_FIELD: 320
};

const TEXT_FIELD_LENGTH = {
  SHORT: 30,
  MIDDLE: 100,
  LONG: 200
};

const LIMIT_PAGINATION = 12;

const DEFAULT_DATE_TIME_FORMAT =
  LocalizeVariable.getDateFormat(true) + " " + LocalizeVariable.getTimeFormat();
  
const DEFAULT_DATE_FORMAT = LocalizeVariable.getDateFormat(true);

export {
  ENTRY_LIMIT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_DATE_FORMAT,
  LIMIT_PAGINATION,
  TEXT_FIELD_LENGTH
};
