import { flatten } from "flat";
import { en as enStyles } from "styles/locales";
const labManager = {
  customer: {
    status: {
      inUse: "In Use",
      discontinued: "Discontinued",
      withdraw: "Withdraw",
      withdrawAfterOneMonth: "WithdrawAfterOneMonth",
      all: "All"
    },
    pms: {
      pmsUsed: "PMS Used",
      notPMSUsed: "Not PMS Used"
    },
    withdraw: {
      afterOneMonth: "Withdrawn after one month",
      now: "Withdraw now"
    }
  },
  user: {
    status: {
      inUse: "In Use",
      discontinued: "Discontinued",
      withdraw: "Withdraw",
      withdrawAfterOneMonth: "WithdrawAfterOneMonth",
      temporarySuspended: "Temporary Suspended",
      all: "All"
    },
    basis: {
      created_order: "Created order",
      signed_date: "Signed date"
    },

    pms: {
      pmsUsed: "PMS Used",
      notPMSUsed: "Not PMS Used"
    },
    withdraw: {
      afterOneMonth: "Withdrawn after one month",
      now: "Withdraw now"
    }
  },
  gender: {
    male: "Male",
    female: "Female"
  },
  registration: {
    status: {
      waiting: "Waiting",
      approved: "Approved",
      refused: "Refused",
      withdraw: "Withdraw",
      cancelWithdraw: "Cancel Withdraw",
      processingTerminated: "Processing Termination",
      completeTerminated: "Completed Termination",
      cancelTerminated: "Cancel Terminated",
      all: "All"
    }
  }
};

const word = {
  language: "Language",
  en: "English",
  ko: "Korean",
  name: "Name",
  hospital: "Hospital",
  id: "ID",
  userId: "User ID",
  password: "Password",
  login: "Clever Login",
  tabletLogin: "Tablet Receipt Login",
  desk: "desk",
  reservation: "reservation",
  chart: "chart",
  photoView: "photo view",
  dentalLab: "labOrder",
  OralExamination: "OralExamination",
  claim: "Claim",
  signAndLog: "Signiture/Log",
  applicationManagement: "Application",
  customerManagement: "Customer",
  notificationManagement: "Notice",
  registrationManagement: "Registration Management",
  userManagement: "User Management",
  activeUserStatistics: "Active User Statistics",
  resetPassword: "Reset Password",
  settings: "Settings",
  dashboard: "Dashboard",
  statistics: "statistics",
  setting: "setting",
  zalo: "zalo",
  "reservation-evn": "reservation",
  total: "total",
  download: "download",
  noselection: "none",
  test: "test",
  manageGold: "Manage Gold",
  preview: "Preview",
  noNotice: "No notice.",
  noPopup: "No popup.",
  created: "Created",
  publish: "Publish",
  unpublish: "Unpublish",
  add: "Add",
  orderSetting: "Order Setting",
  order: "Order",
  publishedPopups: "Published Popups",
  publishStart: "Publish Start",
  publishEnd: "Publish End",
  dialogSize: "Dialog Size",
  title: "Title",
  noticeTab: {
    notice: "Notice",
    popup: "Popup"
  },
  button: {
    ok: "Ok",
    cancel: "Cancel",
    close: "Close",
    save: "Save",
    confirm: "Confirm",
    delete: "Delete",
    leave: "Leave",
    stay: "Stay"
  },
  size: {
    small: "Small",
    medium: "Medium",
    large: "Large"
  },
  message: {
    success: {
      save: "Saved.",
      update: "Updated.",
      delete: "Deleted."
    },
    error: {
      NOTICE03: "Max 5 popups can be published.",
      FIELD_REQUIRED: "This field is required.",
      PUBLISH_DATE: "Please check the Start, End Date.",
      PUBLISH_END: "The publish end date cannot be set to a past date."
    }
  },
  dialog: {
    askDelete: "Delete the item?",
    deleteWarning: "You can’t undo this action.",
    askCancel: "Leave without saving?",
    cancelWarning: "Unsaved changes will be lost."
  },
  hoverTitle: {
    publish: "Published: Displayed on the user side.",
    unpublish: "Unpublished: Not displayed."
  },
  jobTypes: {
    dentist: "dentist",
    dentalHygienist: "dentalHygienist",
    radiologist: "radiologist",
    nursingAssistant: "nursingAssistant",
    dentalConstruction: "dentalConstruction",
    coordinator: "coordinator",
    other: "other",
    doctor: "doctor",
    technician: "Technician",
    accountant: "Accountant"
  },
  jobPosition: {
    medicalTeamMembers: "medicalTeamMembers",
    headOfMedicalTeam: "headOfMedicalTeam",
    mount: "mount",
    headOfBusiness: "headOfBusiness",
    payDoctor: "payDoctor",
    substituteDoctor: "substituteDoctor",
    ledger: "ledger",
    president: "president",
    partTimeJob: "partTimeJob",
    other: "other",
    staff: "Staff",
    manager: "Manager"
  },
  charge: {
    noChoice: "noChoice",
    doctorInCharge: "doctorInCharge",
    staffInCharge: "staffInCharge"
  },
  status: {
    inOffice: "In Office",
    leaveOfAbsence: "Leave of Absence",
    maternityLeave: "Maternity Absence",
    resigned: "Resigned"
  },
  general: "General",
  accountant: "Accountant",
  manager: "Manager",
  payment: "Payment",
  goldManager: "Gold Manager",
  calendar: "Calendar",
  messenger: "Messenger",
  orders: "Orders",
  monthlyBudgetManagement: "Monthly Budget Management",
  employeeInformationManager: "Employee Information Manager",
  authorization: "Authorization",
  otherSetting: "Others (Authorization Setting)",
  permissionCategory: {
    desk: "Desk",
    reservation: "Reservation",
    chart: "Chart",
    photoView: "Photo view",
    oralExam: "Oral examination",
    labOrder: "Dental lab order",
    insurance: "Insurance claim",
    electronicSignatureAndLog: "Sign/log (detailed authority setting)",
    electronicSignature: "Collective electronic signature",
    medicalRecords: "Medical records",
    diagnosticRadiationRecords: "Diagnostic radiation device records",
    personalInformation: "Personal information destruction & management ledger",
    coPaymentManagement: "Co-payment management ledger",
    checkAccessLog: "Check access log",
    log: "Log management",
    workingLog: "Working log",
    purchaseLog: "Purchase log",
    permissionChangeLog: "Permission change log",
    customerManagement: "Customer management",
    ManagementStatistics: "Management statistics",
    cashAndBudgetManagement: "Cash/budget management",
    preferences: "Preferences",
    etc: "Etc(detailed authority setting)",
    permissionSetting: "Permission setting",
    staffSetting: "Staff setting",
    accreditedCertificate: "Accredited certificate",
    salesManagement: "Sales management",
    dailyBudgetManagement: "Daily budget management",
    patientManagementStatistics: "Patient management statistics",
    hospitalSetting: "Hospital setting",
    workTimeSetting: "Working time setting",
    jxSetting: "JX setting",
    discountSetting: "Discount Setting",
    prescriptionSetting: "Prescription setting",
    treatButton: "Treat bundle",
    wireSetting: "Calibration wire settings",
    maSetting: "MA setting",
    fileSetting: "File setting",
    fixtureSetting: "Fixture setting",
    ccSetting: "CC setting",
    treatmentPlanSetting: "PL Setting",
    radioGraphDecodeSetting: "Radio graph decode setting",
    oeSetting: "O/E Setting",
    uninsuranceActionSetting: "Uninsurance setting",
    checkInsurance: "Check insurance",
    nextSetting: "Next setiing",
    patientSetting: "Patient information setting",
    etcIncomeAndOutgoingSetting: "Etc. income setting",
    schoolSetting: "School setting",
    bankAccountSetting: "Bank account setting(School dental check up)",
    oralExamPhraseSetting: "Oral exam phrase setting ",
    labSetting: "Dental lab setting",
    crownSetting: "Crown setting",
    requestDetailSetting: "Request details setting",
    smtpSetting: "SMTP setting",
    smsSetting: "SMS phrase setting",
    entrustedInstitutionSetting: "Entrusted institution setting",
    partnerSetting: "Partner setting",
    ktCallManagerAPISetting: "API setting",
    popBillSMSSetting: "SMS setting"
  },
  payBank: {
    shinhanBank: "shinhanBank",
    kookminBank: "kookminBank",
    wooriBank: "wooriBank",
    hanaBank: "hanaBank",
    scFirstBank: "scFirstBank",
    koreaCityBank: "koreaCityBank",
    agriculturalCooperation: "agriculturalCooperation",
    siouxCooperation: "siouxCooperation",
    industrialBank: "industrialBank",
    exportImportBankOfKorea: "exportImportBankOfKorea",
    corporateBank: "corporateBank",
    gyeongnamBank: "gyeongnamBank",
    gwangjuBank: "gwangjuBank",
    daeguBank: "daeguBank",
    busanBank: "busanBank",
    jeonbukBank: "jeonbukBank",
    jejuBank: "jejuBank"
  },
  receiptStatus: {
    receiptSelect: "receiptSelect",
    receipt: "receipt",
    treatmentInProgress: "treatmentInProgress",
    treatmentComplete: "treatmentComplete",
    storageComplete: "storageComplete",
    receiptDelete: "receiptDelete",
    checkUp: "Check up"
  },
  scheduleType: {
    vacation: "Annual leave",
    morningHalfVacation: "AM half annual leave",
    afternoonHalfVacation: "PM half annual leave",
    seminar: "Seminar/Conference",
    goingOut: "Going out",
    visit: "Visit",
    etc: "ETC."
  },
  scheduleStatus: {
    implementation: "Implementation",
    notImplementation: "Not Implementation",
    cancel: "Cancel",
    hold: "Hold"
  },
  scheduleContextMenu: {
    registration: "Registration",
    modify: "Modify",
    implementation: "Implementation",
    notImplementation: "Not Implementation",
    hold: "Hold",
    cancel: "Cancel",
    delete: "Delete"
  },
  patientStatus: {
    reservation: "Reservation",
    implementation: "Implementation",
    notImplementation: "Not Implementation",
    receipt: "Receipt",
    cancel: "Cancel",
    hold: "Hold"
  },
  patientContextMenu: {
    reservation: "예약",
    modify: "예약수정",
    select: "환자선택",
    implementation: "예약이행",
    receipt: "바로접수",
    cancel: "예약취소",
    hold: "예약보류",
    delete: "예약삭제"
  },
  oralexamType: {
    general: "General exam",
    allLife: "All life exam",
    infantEighteenToTwentyNine: "Infant & Child 18-29",
    infantFortyTwoToFiftyThree: "Infant & Child 42-53",
    infantFiftyFourToSixtyFive: "Infant & Child 42-65",
    outOfSchool: "Out of school",
    noneSelect: "미선택",
    student: "student"
  },
  oralexamFile: {
    generate: "Generate",
    nonGenerate: "NonGenerate"
  },
  oralexamUnit: {
    tooth: "Tooth",
    plane: "Surface",
    double: "Double",
    multiple: "Multiple",
    many: "Many"
  },
  oralexamToothType: {
    caries: "caries",
    cariesDoubt: "cariesDoubt",
    rehabilitate: "rehabilitate",
    sealant: "sealant",
    normal: "normal"
  },
  patientType: {
    initPatient: "Init",
    newPatient: "New",
    existingPatient: "Old"
  },
  workingForm: {
    fullTime: "Full Time",
    partTime: "Part Time",
    substituteDoctor: "Substitute Doctor",
    other: "other"
  },
  treatmentUnit: {
    perTooth: "치아당",
    perRootCanal: "근관당",
    perHalfJaw: "1/2악당",
    perThirdJaw: "1/3악당",
    perJaw: "악당",
    wholeJaws: "전악"
  },
  docCategories: {
    general: "General",
    certification: "Certification",
    oralExamination: "OralExamination"
  },
  docTypes: {
    questionnaire: "Questionnaire",
    consent: "Consent",
    precaution: "Precaution",
    healthInsurance: "HealthInsurance",
    medicalBenefit: "MedicalBenefit",
    docIssue: "Certification",
    oralExamQuestionnaire: "문진표",
    oralExamResults: "결과 통보서"
  },
  docNamesQuestionnaire: {
    newPatientDetail: "New Patient(Detail)",
    newPatientSimple: "New Patient(Simple)",
    medicalHistory: "Medical History",
    implantPatient: "Implant Patient",
    antiSmokingTreatment: "Anti-Smoking Treatment",
    jawJointDisease: "Jaw Joint Disease"
  },
  docNamesConsent: {
    dentalSurgery: "Dental Surgery",
    rootCanal: "Root Canal",
    implant: "Implant",
    sedationMethod: "Sedation Method",
    denture: "Denture",
    orthodontics: "Orthodontics",
    photographConsent: "Photograph Consent",
    medicalRecordsAccessConsent: "Medical Records Access Consent",
    medicalRecordsAccessDelegation: "Medical Records Access Delegation",
    extractionTakingOver: "Extraction Taking Over",
    personalInformationCollection: "Personal Information Collection",
    personalInformationThirdParties: "Personal Information Third Parties"
  },
  docNamesPrecaution: {
    tartarRemoval: "Tartar Removal",
    implant: "Implant",
    denture: "Denture",
    toothExtraction: "Tooth Extraction",
    teethWhitening: "Teeth Whitening",
    rootCanal: "Root Canal",
    orthodHeadGearFaceBow: "Orthod HeadGear FaceBow",
    orthodFaceMaskChinCap: "Orthod FaceMask ChinCap",
    orthodRubberBand: "Orthod RubberBand",
    orthodFixed: "Orthod Fixed",
    orthodRemovableAppliance: "Orthod Removable Appliance",
    orthodTransparentAppliance: "Orthod Transparent Appliance"
  },
  docNamesHealthInsurance: {
    tartarRemovalRegistrationCancel: "Tartar Removal Registration Cancel",
    dentureRegistration: "Denture Registration",
    dentureChange: "Denture Change",
    dentureMaintenanceCancal: "Denture Maintenance Cancal",
    implantRegistration: "Implant Registration",
    implantChange: "Implant Change"
  },
  docNamesMedicalBenefit: {
    tartarRemovalRegistrationCancel: "Tartar Removal Registration Cancel",
    dentureRegistration: "Denture Registration",
    dentureChange: "Denture Change",
    dentureMaintenanceCancal: "Denture Maintenance Cancal",
    implantRegistration: "Implant Registration",
    implantChange: "Implant Change"
  },
  docStatus: {
    saved: "Saved",
    issued: "Issued"
  },
  docPurposes: {
    insurance: "Insurance",
    work: "Work",
    school: "School",
    military: "Military",
    police: "Police",
    court: "Court",
    treatment: "Treatment",
    etc: "etc"
  },
  treatmentPlan: {
    status: {
      idle: "Not saved",
      temporaryStorage: "Temporary storage",
      confirmation: "Confirmation",
      beScheduled: "Be Scheduled",
      hold: "Hold",
      cancel: "Cancel",
      completion: "Completion",
      closing: "Closing"
    }
  },
  photoActions: {
    tags: "Tags",
    rename: "Rename",
    other: "Other",
    delete: "Delete",
    detail: "Detail",
    save: "Save",
    saveas: "Save As",
    download: "Download"
  },
  photoTypes: {
    all: "All",
    panorama: "Panorama",
    pa: "PA",
    cephalo: "Cephalo",
    capture: "Capture",
    oralphoto: "Oralphoto",
    image: "Image",
    document: "Document",
    other: "Other"
  },
  photoFilters: {
    brightness: "Brightness",
    saturation: "Saturation",
    contrast: "Contrast",
    invert: "Invert"
  },
  consultActions: {
    frompc: "Download from PC"
  },
  cavityInput: {
    mesical: "Mesical",
    buccal: "Buccal",
    distal: "Distal",
    lingual: "Lingual",
    occlusal: "Occlusal"
  },
  relationship: {
    myself: "myself",
    spouse: "spouse",
    child: "child",
    parent: "parent",
    brothers: "brothers",
    grandparents: "grandparents",
    grandchildren: "grandchildren",
    relatives: "relatives",
    work: "work",
    friend: "friend",
    school: "school",
    family: "family",
    religion: "religion",
    other: "other"
  },
  hospitalInfo: {
    type: {
      clinic: "Clinic",
      cityHospital: "Hospital(City)",
      townHospital: "Hospital(Town)",
      cityGeneralHospital: "General Hospital(City)",
      townGeneralHospital: "General Hospital(Town)"
    }
  },
  docNamesDocIssue: {
    diagnosis: "Diagnosis",
    injuryDiagnosis: "Injury Diagnosis",
    treatmentConfirmation: "Treatment Confirmation",
    operationConfirmation: "Operation Confirmation",
    treatmentRequest: "Treatment Request",
    treatmentPlan: "Treatment Plan",
    doctorNote: "Doctor Note",
    ambulatoryConfirmation: "Ambulatory Confirmation",
    expenseEstimation: "Expense Estimation",
    militaryDiagnosis: "Military Diagnosis",
    radiationOpinion: "Radiation Opinion",
    visitingNursingDirections: "Visiting Nursing Directions",
    medicalBenefitRequest: "Medical Benefit Request",
    treatmentResponse: "Treatment Response",
    medicalRecord: "Medical Record"
  },
  labOrder: {
    newRequest: "New",
    modifyRequest: "Fix",
    reRequest: "Remake",
    ordered: "Ordered",
    requestCompleted: "requestCompleted",
    cadDesign: "CAD Design",
    confirmRequest: "Confirm Req.",
    confirmComplete: "Confirmed",
    inProd: "In Prod",
    complete: "Complete",
    shipped: "Shipped",
    non: "",
    arrive: "Arrived",
    nonArrive: "Not Arrived",
    check: "check",
    nonCheck: "nonCheck",
    clever: "clever",
    nonClever: "nonClever",
    non1: "",
    arrive1: "Arrival",
    nonArrive1: "Not Arrival",
    detail: "Details",
    confirm: "Confirm",
    request: "Request",
    mend: "Mend",
    pending: "Pending"
  },
  consulting: {
    importantFactor: {
      cost: "Cost",
      visitCount: "Number of visit",
      aesthetic: "Aesthetic",
      dentalFear: "Dental fear",
      skills: "Doctor's skill",
      services: "Service( Kindness, etc. )"
    }
  },
  oralExam: {
    phraseSetting: {
      dataType: {
        list: "",
        accordingToSchool: ""
      },
      school: {
        elementary: "Elementary students",
        middle: "Middle students",
        high: "High students"
      },
      category: {
        infant: {
          default: "Infant screening",
          etc: "Other site inspection findings",
          additional: "Additional measures",
          analysis: "Analysis"
        },
        normalAndWholeLife: {
          default: "General/Lifetime screening",
          actions: "Actions",
          activeManagement: "Active management"
        },
        studentCheckup: {
          default: "Student checkup",
          comprehensiveOpinion: "Comprehensive opinion",
          homeAdvice: "Advice at home",
          developmentDirection: "Direction of oral Heath"
        },
        outOfSchool: {
          default: "Youth outside school",
          actions: "Remark ans measure"
        }
      }
    }
  },
  uninsurance: {
    actions: {
      unit: {
        perTooth: "Per tooth",
        perJaw: "Per jaw(Upper, Lower)",
        perHalfJaw: "Per half jaw",
        perThirdJaw: "Per third jaw",
        perOral: "Per oral(Once a day)",
        standardShooting: "Standard shooting(14 photos)"
      },
      details: {
        implant: "Implant",
        denture: "Denture",
        childrenAndPrevention: "Children, Prevention",
        correction: "Correction",
        others: "Others",
        beauty: "Beauty(taxes)",
        listedUninsurance: "Listed uninsurance",
        preservation: "Preservation",
        prothesis: "Prothesis"
      }
    }
  },
  treat: {
    action: "action",
    material: "material",
    uninsured: "uninsured",
    medicine: "medicine"
  },
  logCategory: {
    patient: "patient",
    docIssue: "docIssue",
    reservation: {
      patient: "Patient Reservation",
      schedule: "Hospital Schedule"
    },
    receipt: "receipt",
    uninsuranceSetting: "Uninsurance Setting",
    chart: {
      tx: "Chart(TX)",
      nx: "Chart(NX)",
      oe: "Chart(OE)",
      pl: "Chart(PL)",
      ma: "Chart(MA)",
      cc: "Chart(CC)",
      rx: "Chart(RX)",
      di: "Chart(DI)",
      xr: "Chart(XR)",
      mm: "Chart(MM)",
      ps: "Chart(PS)"
    },
    payment: "Payment",
    labOrder: "Lab order",
    manageGold: "Manage Gold",
    staff: "Staff",
    permission: "Permission",
    connection: "Connection",
    connectionLogLedger: "Connection log checking ledger",
    destructionLogLedger: "Destruction ledger of personal information"
  },
  oralExamState: {
    oralExaminationUnentered: "oralExaminationUnentered",
    oralResultsUnentered: "oralResultsUnentered",
    completedScreening: "completedScreening"
  },
  patient: {
    male: "male",
    female: "female"
  },
  partner: {
    clinic: {
      goldSourcingMethod: {
        sendGold: "Send Gold",
        useSuppliedGold: "Use Supplied Gold",
        useLabsGold: "Use Lab's Gold"
      }
    },
    lab: {
      goldType: {
        inlay: "Inlay",
        aType: "A-Type",
        sa: "S-A",
        pt: "PT",
        pfg: "PFG"
      }
    }
  },
  signUp: {
    ui: {
      labId: "Lab ID",
      stepsForm: "Application Form",
      labCheckRequire:
        "*4~30 characters consisting of lowercase letters and numbers.​",
      userId: "User ID",
      acceptAllTerms: "Accept All Terms And Conditions",
      birthday: "Birth Date",
      checkId: "Check ID",
      completeNoticeMessage1:
        "Your registration has been received. The verification process may take five days.",
      completeNoticeMessage2:
        "We will send you a confirmation email to the address you have provided.",
      completeNoticeMessage3: "Enjoy the best clinic platform, Clever Lab!",
      conditionsCheck: "Terms (Option)",
      confirmPassword: "Confirm Password",
      failedMessageLine1:
        "Please go back to the previous step and proceed again. ",
      failedMessageLine2:
        "If the problem persists, please contact customer service.",
      failedRegistration: "Registration Failed.",
      idUsed: "Lab ID already in use.",
      availableId: "Available ID.",
      noStrong:
        "The length of the ID must be from 4 to 30 characters consisting of lowercase letters and numbers.",
      labCheckRequire:
        "*4~30 characters consisting of lowercase letters and numbers.​",
      labId: "Lab ID",
      netWorkErr: "Network Error",
      netWorkErrMessageLine1: "Please refresh.",
      netWorkErrMessageLine2:
        "If the problem persists, please contact customer service.",
      passwordCheck: "*Must be  8 letters more and numbers, Special Characters",
      passwordNote:
        "When the registration becomes approved, a default password is issued",
      signUpTitle: "Clever Lab Registration",
      stepsComplete: "Complete Registration",
      stepsForm: "Application Form",
      stepsTerm: "Terms and Conditions",
      termsCheck: "Terms (Required)",
      thanks: "Thank You",
      acceptAllTerms: "Accept All Terms And Conditions",
      birthday: "Birth Date",
      checkId: "Check ID",
      completeNoticeMessage1:
        "Your registration has been received. The verification process may take five days.",
      completeNoticeMessage2:
        "We will send you a confirmation email to the address you have provided.",
      completeNoticeMessage3: "Enjoy the best clinic platform, Clever Lab!",
      conditionsCheck: "Terms (Option)",
      confirmPassword: "Confirm Password",
      failedMessageLine1:
        "Please go back to the previous step and proceed again. ",
      failedMessageLine2:
        "If the problem persists, please contact customer service.",
      failedRegistration: "Registration Failed.",
      labCheckRequire:
        "*4~30 characters consisting of lowercase letters and numbers.​",
      registration: {
        afterRegistrationGuide:
          "In Clever Lab, after receiving the membership application, the person in charge makes a happy call to proceed with the registration approval process.",
        signUpOperatorGuide:
          "Please sign up for a laboratory operator account only. Employee accounts can be used after registration in Employee Management."
      }
    },
    error: {
      address: "This field is required.",
      birthDate: "Birth date is required.",
      cellPhoneNumber: "Cell phone is a invalid value.",
      confirmPassword: "Password does not match",
      email: "Email is a invalid value.",
      labId:
        "Lab ID must be between 4 and 20 characters long, and contain numbers or lowercase letters.",
      labName: "This field is required.",
      labNameLength: "Lab name must be between 2 and 20 characters.",
      managerName: "This field is required.",
      managerNameLength: "Manager Name must be between 2 and 20 characters.",
      password:
        "Password must be between 8 and 20 characters long, and contain numbers, letters, symbols.",
      tellPhoneNumber: "Tell phone is a invalid value.",
      labTelLength: "Tell No. must be between 9 and 11 characters.",
      cellTelLength: "Cell No. must be between 9 and 11 characters.",
      userId: "*4~30 characters consisting of lowercase letters and numbers.​",
      brn: "Brn field is required.",
      staffId:
        "User ID must be between 4 and 20 characters long, and contain numbers or lowercase letters.",
      brnExists: "This is an already registered Business Number.",
      required: "This field is required.",
      brnNumber:
        "Business registration number must be between 2 and 20 characters",
      referralLength: "Referrer must be between 2 and 20 characters."
    }
  },
  common: {
    ui: {
      labName: "Lab Name",
      address: "Address",
      managerName: "Manager Name",
      cellNo: "Cell No.",
      telNo: "Tel No.",
      email: "Email",
      save: "Save",
      password: "Password",
      ok: "Ok",
      cancel: "Cancel",
      erc: "BRN",
      brn: "BRN",
      brc: "BRC",
      labId: "Lab ID",
      verifyPassword: "Confirm Password",
      businessRegistrationNumber: "Business Registration Number",
      accountType: "Account Type",
      no: "No",
      userID: "User ID",
      registrationDate: "Registration Date",
      registrationStatus: "Register Status",
      registerUpdated: "Register Updated",
      manager: "Manager",
      usageStatus: "Usage Status",
      usageUpdated: "Usage Updated",
      eMail: "E-mail",
      referral: "Referrer",
      createdOrder: "Created Order",
      signedUp: "Signed Up",
      lastLogin: "Last Login",
      dentalLabName: "Dental Lab Name"
    },
    error: {
      invalidFileType: "Only PNG, JPG, JPEG files are allowed",
      invalidFileSize: "The maximum size for attached files is 10MB"
    }
  },
  accountType: {
    all: "All",
    lab: "Lab",
    test: "Test",
    msg: {
      switchedTo: "Account has been set as a {targetAccountType} account."
    }
  },

  settings: {
    smtp: {
      custom: "Custom",
      emailAccount: "E-mail adress",
      help: "Help",
      "helpGuide.1":
        "*you need to enable the smtp/imap settings of your email account.",
      "helpGuide.2": "For detail, please refer to the help menu below.",
      port: "SMTP Port Number",
      sentTestMail: "Send Test Mail",
      setupConfirm:
        "SMTP settings are required for email transmission. Would you like to set up SMTP?",
      smtpServer: "SMTP Server",
      smtpSetting: "SMTP server address",
      ssl: "Ssl encryption connections",
      toastFailedSentTestMail: "Failed sending mail to {email}.",
      toastSentTestMail: "Mail has been sent to {email}",
      password: "Login Password",
      saveSuccess: "The settings have been saved."
    },
    ui: {
      general: "General",
      administer: "Administer",
      smtp: "SMTP"
    }
  }
};

const log = {
  duplicated: `{name} already ({name}: {id})`,
  invalidPassword: `Invalid password`
};

const en = flatten({ ...enStyles, ...word, ...log, ...labManager });

export default en;
