import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";

const makeList = type => {
  return Object.values(type).map(value => ({
    key: value.key,
    value: value.value,
    label: <FormattedMessage id={value.localeKey} />
  }));
};
const makeSomeList = (type, values) => {
  return Object.values(type)
    .filter(value => values.includes(value.value))
    .map(value => ({
      value: value.value,
      label: <FormattedMessage id={value.localeKey} />
    }));
};

const reformatDate = date => {
  return moment.unix(date).format("YYYY/MM/DD");
};

const makeType = (value, localeKey) => ({ value: value, localeKey: localeKey });
const makeIndexList = type =>
  Object.values(type).reduce((accumulator, currentValue) => {
    accumulator[currentValue.value] = currentValue.localeKey;
    return accumulator;
  }, {});
const makeIndexFormattedMessageList = type =>
  Object.values(type).reduce((accumulator, currentValue) => {
    accumulator[currentValue.value] = (
      <FormattedMessage id={currentValue.localeKey} />
    );
    return accumulator;
  }, {});
export {
  reformatDate,
  makeList,
  makeSomeList,
  makeType,
  makeIndexList,
  makeIndexFormattedMessageList
};
