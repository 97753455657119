import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CCSearchTextField } from "components";
import CVDualFlattenDateField from "components/CVDualFlattenDateField";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { CCButton, CCTextField } from "styles/components";
import { SearchIcon } from "styles/icons";
import { makeList } from "types";
import { BASIS_USER_ACTIVE } from "types/userManage";

const useStyles = makeStyles(theme => ({
  input: { backgroundColor: theme.palette.common.white },
  searchtext__field: {
    width: 243
    // marginLeft: 32
  },
  date__field: {
    width: 150
  },
  ml8: {
    marginLeft: 8
  },
  selection: {
    width: 142
  }
}));

const UserFilter = props => {
  const { filter, onSearch } = props;

  const classes = useStyles();
  const [value, setValue] = useState(filter);

  const userBasisList = makeList(BASIS_USER_ACTIVE);

  const inputRef = useRef();

  useEffect(() => {
    setValue(filter);
  }, [filter]);

  return (
    <>
      <CCSearchTextField
        label={"Lab Name, ID"}
        className={classes.searchtext__field}
        variant="outlined"
        margin="dense"
        inputRef={inputRef}
        value={value.searchText}
        onKeyPress={e => {
          if (e.key === "Enter") {
            onSearch({ ...value, searchText: inputRef.current.value });
          }
        }}
        onChange={e => {
          setValue(prevState => ({ ...prevState, searchText: e }));
        }}
        onClear={() => {
          inputRef.current.value = "";
          onSearch({ ...value, searchText: "" });
          // handleSearchTextClick();
        }}
      />
      {/* <CCTextField
        className={classes.searchtext__field}
        variant="outlined"
        margin="dense"
        label="Lab, Manager, Cell No., Tel No."
        InputProps={{ classes: { root: classes.input } }}
        value={value.searchText}
        onChange={e => {
          setValue({ ...value, searchText: e.target.value });
        }}
      /> */}
      <CCTextField
        value={value.basisPeriod}
        label={"Basis"}
        select
        variant="outlined"
        margin="dense"
        className={classes.selection}
        onChange={e => {
          setValue({ ...value, basisPeriod: e.target.value });
          onSearch({
            ...value,
            basisPeriod: e.target.value
          });
        }}
      >
        {userBasisList.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </CCTextField>
      <CVDualFlattenDateField
        // disabled
        readOnly
        className={classes.date__field}
        labelLeft={"Start Date"}
        labelRight={"End Date"}
        valueLeft={value?.from ? moment(value?.from, "X") : false}
        valueRight={value?.to ? moment(value?.to, "X") : false}
        onChangeLeft={e => {
          let momentRightValue = moment(value?.to, "X").startOf("day");
          let momentLeftValue = moment(e).startOf("day");

          const isInvalidDateRange = moment(momentLeftValue).isSameOrAfter(
            moment(momentRightValue)
          );

          setValue({
            ...value,
            from: moment(e).unix(),
            to: isInvalidDateRange ? moment(e).unix() : value.to
          });
          onSearch({
            ...value,
            from: moment(e).unix(),
            to: isInvalidDateRange ? moment(e).unix() : value.to
          });
        }}
        onChangeRight={e => {
          let momentRightValue = moment(e).startOf("day");
          let momentLeftValue = moment(value?.from, "X").startOf("day");

          const isInvalidDateRange = moment(momentLeftValue).isSameOrAfter(
            moment(momentRightValue)
          );

          setValue({
            ...value,
            to: moment(e).unix(),
            from: isInvalidDateRange ? moment(e).unix() : value.from
          });
          onSearch({
            ...value,
            to: moment(e).unix(),
            from: isInvalidDateRange ? moment(e).unix() : value.from
          });
        }}
      />
      <CCButton
        variant="contained"
        color="secondary"
        startIcon={<SearchIcon />}
        className={classes.ml8}
        onClick={() => {
          onSearch && onSearch({ ...value });
        }}
      >
        Search
      </CCButton>
    </>
  );
};

export default UserFilter;
