import { useLazyQuery } from "@apollo/client";
import { Button, makeStyles } from "@material-ui/core";
import { translate } from "components";
import Excel from "exceljs";
import FileSaver from "file-saver";
import moment from "moment";
import { GET_LAB_ACTIVE_LIST } from "queries/customer";
import { useIntl } from "react-intl";
import { ExcelIcon } from "styles/icons";
import { reformatDate } from "types/utils";

const useStyles = makeStyles(theme => ({
  excel__icon: {
    cursor: "pointer",
    color: theme.palette.icon
  }
}));

const UserDownloadExcelBtn = ({ filename, filter, disabled }) => {
  const intl = useIntl();
  const classes = useStyles();
  const downloadFileName = `${filename}_${moment().format("YYYY/MM/DD")}.xlsx`;

  const [fetchData, { data }] = useLazyQuery(GET_LAB_ACTIVE_LIST, {
    onCompleted: () => {
      handleDownload();
    },
    onError: () => {}
  });

  const handleClickBtn = () => {
    fetchData({ variables: { input: filter } }).then(({ data }) => {
      let listData = [];
      listData = data?.labActiveList?.items.map((itm, idx) => ({
        ...itm,
        no: idx + 1,
        labId: itm.lab?.labId,
        labName: itm.lab?.labName,
        lastLogin: itm.lastLogin ? reformatDate(itm.lastLogin) : "",
        signUp: itm.signUp ? reformatDate(itm.signUp) : ""
      }));
      handleDownload(listData);
    });
  };

  const handleDownload = data => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Active User Statistics");

    const style = {
      alignment: {
        horizontal: "center",
        vertical: "middle"
      }
    };

    worksheet.columns = [
      { header: translate(intl, "common.ui.no"), key: "no", style },
      {
        header: translate(intl, "common.ui.dentalLabName"),
        key: "labName",
        style
      },
      { header: translate(intl, "common.ui.labId"), key: "labId", style },
      {
        header: translate(intl, "common.ui.createdOrder"),
        key: "createdOrders",
        style
      },
      { header: translate(intl, "common.ui.signedUp"), key: "signUp", style },
      {
        header: translate(intl, "common.ui.lastLogin"),
        key: "lastLogin",
        style
      }
    ];

    // add rows
    data.forEach(itm => {
      worksheet.addRow(itm);
    });

    // style header
    const header = worksheet.getRow(1);
    header.font = { bold: true };
    header.alignment = { horizontal: "center", vertical: "middle" };

    workbook.xlsx
      .writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), downloadFileName))
      .catch(err => console.log("Error writing excel export", err));
  };

  return (
    <Button
      className={classes.excel__icon}
      disabled={disabled}
      onClick={handleClickBtn}
    >
      <ExcelIcon />
    </Button>
  );
};

UserDownloadExcelBtn.defaultProp = {
  data: [],
  filename: "file"
};

UserDownloadExcelBtn.propTypes = {};

export default UserDownloadExcelBtn;
