import { useQuery } from "@apollo/react-hooks";
import { Box, Grid, LinearProgress } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { CVTable, TimerWithRefetch } from "components";
import moment from "moment";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import {
  CCDialogContent,
  CCDialogTitle,
  CCTypography
} from "styles/components";
import { UserFilter } from "./components";

import { GET_LAB_ACTIVE_LIST } from "queries/customer";
import { LIMIT_PAGINATION } from "types/constants";
import UserDownload from "./components/UserDownload/UserDownload";

const LIMIT = LIMIT_PAGINATION;

const INITIAL_FILTER = {
  from: moment().startOf("day").subtract(6, "days").unix(),
  to: moment().endOf("day").unix(),
  searchText: "",
  basisPeriod: "CREATED_ORDER",
  limit: LIMIT,
  page: 1
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.common.white
  },
  timer: {
    position: "absolute",
    zIndex: 1200,
    top: 18,
    right: 32,
    display: "flex"
  },
  timer__divider: {
    height: 36,
    borderLeft: "3px solid rgba(0, 0, 0, 0.16)",
    margin: "0 16px"
  },
  paper: { height: "100%", width: "100%" },
  content: { height: "calc(100% - 55px)", overflow: "hidden" },
  container: { height: "100%" },

  item: { width: "100%" },
  container__filter: {
    width: "100%",
    padding: "4px 12px",
    margin: "0px",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  input: { backgroundColor: theme.palette.common.white },

  item__table: {
    position: "relative",
    marginBottom: theme.spacing(1),
    height: "74vh"
  },
  table: {
    height: "100%",
    textAlign: "center",

    "& .MuiTypography-colorInherit": {
      color: theme.palette.input.main
    }
  },
  table__body: {
    backgroundColor: "White",
    "& > div:nth-child(even)": {
      background: theme.palette.common.white
    }
  },

  table__head__cell: {
    flex: "auto"
  },
  leftAlign: {
    justifyContent: "flex-start",
    textAlign: "start"
  },
  numberCol: {
    maxWidth: 100
  },
  table__cell: {
    padding: "6px 4px"
  },
  container__status: {
    position: "relative",
    display: "flex",
    borderTop: `1px solid ${theme.palette.border.main}`,
    padding: "28px 12px"
  }
}));

const initializer = {
  filter: INITIAL_FILTER,
  isFilterLoading: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "filter":
      return {
        ...state,
        filter: action.target
      };

    case "setIsFilterLoading":
      return {
        ...state,
        isFilterLoading: action.target
      };

    case "reset":
      return { ...initializer };
    default:
      break;
  }

  return state;
};

const ActiveUserStatistics = () => {
  const classes = useStyles();

  const [state, dispatchState] = useReducer(reducer, initializer);
  const [activeUserData, setActiveUserData] = useState([]);

  const { filter } = state;

  const {
    data: dataLabList,
    loading,
    error,
    refetch: refetchLabList
  } = useQuery(GET_LAB_ACTIVE_LIST, {
    variables: {
      input: INITIAL_FILTER
    },
    onCompleted: () => {
      dispatchState({
        type: "setIsFilterLoading",
        target: false
      });
    }
  });

  useEffect(() => {
    setActiveUserData(
      dataLabList?.labActiveList?.items?.map((item, index) => ({
        ...item,
        noItem: index + 1 + (state.filter.page - 1) * 12
      })) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLabList]);

  /**
   * Create toast message to display when change status
   * @param {String} newStatus
   * @param {String} labName
   * @returns {String}
   */
  /**
   * Open toast when action success
   * @param {String} message
   */

  const heads = useMemo(
    () => [
      {
        key: "noItem",
        label: "No",
        className: clsx(classes.table__cell, classes.numberCol),
        component: ({ cellData }) => {
          return cellData;
        }
      },
      {
        key: "labName",
        label: "Dental Lab Name",
        className: clsx(classes.table__cell, classes.leftAlign),

        component: ({ rowData }) => {
          return rowData.lab?.labName;
        }
      },
      {
        key: "lab",
        label: "Lab ID",
        className: clsx(classes.table__cell, classes.leftAlign),
        component: ({ rowData }) => {
          return rowData.lab?.labId;
        }
      },
      {
        key: "createdOrders",
        label: "Created Orders",
        className: classes.table__cell,
        component: ({ cellData }) => {
          return cellData;
        }
      },
      {
        key: "signUp",
        label: "Singed up",
        className: classes.table__cell,
        component: ({ cellData }) => {
          return (
            <CCTypography variant="body1">
              {cellData ? moment.unix(cellData)?.format("YYYY/MM/DD") : ""}
            </CCTypography>
          );
        }
      },
      {
        key: "lastLogin",
        label: "Last Login",
        className: classes.table__cell,
        component: ({ cellData }) => {
          return (
            <CCTypography variant="body1">
              {cellData ? moment.unix(cellData)?.format("YYYY/MM/DD") : ""}
            </CCTypography>
          );
        }
      }
    ],
    [classes]
  );

  const handleOnChangePagination = (event, value) => {
    dispatchState({
      type: "filter",
      target: { ...filter, page: value }
    });
    dispatchState({
      type: "setIsFilterLoading",
      target: true
    });
    refetchLabList({
      input: {
        ...filter,
        page: value
      }
    });
  };

  const totalPages = useMemo(
    () => Math.ceil(dataLabList?.labActiveList.total / LIMIT),
    [dataLabList]
  );

  const handleOnSearch = useCallback(
    value => {
      dispatchState({
        type: "filter",
        target: { ...value, basisPeriod: value.basisPeriod, page: 1 }
      });
      dispatchState({
        type: "setIsFilterLoading",
        target: true
      });
      refetchLabList({
        input: {
          page: 1,
          limit: LIMIT,
          from: value.from,
          searchText: value.searchText,
          to: value.to,
          basisPeriod: value.basisPeriod
        }
      });
    },
    [dispatchState, refetchLabList]
  );

  const refetchData = () => {
    dispatchState({
      type: "reset"
    });

    dispatchState({
      type: "setIsFilterLoading",
      target: true
    });
    refetchLabList({
      input: INITIAL_FILTER
    });
  };

  if (error) return <div>Some thing went wrong!</div>;

  if (loading) return <LinearProgress color="secondary" />;

  return (
    <>
      <Box className={classes.timer}>
        <TimerWithRefetch refetch={refetchData} />
        <div className={classes.timer__divider}></div>
      </Box>
      <Box className={classes.root}>
        <Box className={classes.paper}>
          <CCDialogTitle>Active User Statistics</CCDialogTitle>
          <CCDialogContent className={classes.content} noPadding>
            <Grid
              container
              direction={"column"}
              wrap={"nowrap"}
              className={classes.container}
            >
              {/* <Grid item className={classes.container__status}>
                <StatisticBox statistics={statistics} />
              </Grid> */}
              <Grid
                className={classes.container__filter}
                container
                justify="space-between"
              >
                <Grid>
                  <Grid container justify="flex-start" alignItems="center">
                    <UserFilter filter={filter} onSearch={handleOnSearch} />
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container>
                    <UserDownload
                      filename="Active_User_Statistics"
                      filter={{
                        ...filter,
                        page: 1,
                        limit: dataLabList?.labActiveList?.total || 0
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classes.item__table} item>
                {/* <div
                  style={{
                    width: "100%",
                    height: "90%",
                    position: "absolute",
                    top: 36
                  }}
                >
                  <Loading open={state.isFilterLoading} />
                </div> */}

                <CVTable
                  heads={heads}
                  contents={activeUserData}
                  classes={{
                    table__body: classes.table__body,
                    table__head__cell: classes.table__head__cell
                  }}
                  className={classes.table}
                />
              </Grid>

              <Grid container item justifyContent="center" direction="column">
                <Pagination
                  count={totalPages}
                  page={filter.page}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  onChange={handleOnChangePagination}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            </Grid>
          </CCDialogContent>
        </Box>
      </Box>
    </>
  );
};

export default ActiveUserStatistics;
