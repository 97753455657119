import React from "react";

const ActiveUserIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 17.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 0h1A3.5 3.5 0 0 1 23 21v1.5h-9V21a3.5 3.5 0 0 1 3.5-3.5h1zM9 2h5v15.354A4.869 4.869 0 0 0 12.009 21H9V2zm12 10.527V9h-5v2.544A3.774 3.774 0 0 1 17.958 11c1.246 0 2.35.6 3.042 1.527zM7 7v14H2V7h5z"
      fill="currentColor"
    />
  </svg>
);
export default ActiveUserIcon;
